import auditLog from './audit-log.json';
import businessAccount from './business-account.json';
import businessApplication from './business-application.json';
import client from './client.json';
import common from './common.json';
import corridor from './corridor.json';
import corridorType from './corridor-type.json';
import cost from './cost.json';
import costManagement from './cost-management.json';
import currency from './currency.json';
import currencyRate from './currency-rate.json';
import dashboard from './dashboard.json';
import error from './error.json';
import feeCommission from './fee-commission.json';
import feeGroup from './fee-group.json';
import feeManagement from './fee-management.json';
import login from './login.json';
import passwordChange from './password-change.json';
import passwordReset from './password-reset.json';
import profile from './profile.json';
import provider from './provider.json';
import report from './report.json';
import role from './role.json';
import transaction from './transaction.json';
import transactionBundle from './transaction-bundle.json';
import user from './user.json';

const en = {
  auditLog,
  businessAccount,
  businessApplication,
  client,
  common,
  corridor,
  corridorType,
  cost,
  costManagement,
  currency,
  currencyRate,
  dashboard,
  error,
  feeCommission,
  feeGroup,
  feeManagement,
  login,
  passwordChange,
  passwordReset,
  profile,
  provider,
  report,
  role,
  transaction,
  transactionBundle,
  user,
};

export default en;
