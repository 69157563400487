import React from 'react';

import { useLang } from 'hooks';
import { DashboardLayout } from 'layouts';
import { Can, SinglePage } from 'components/layout';
import { Grid } from 'components/ui';
import { TransactionsView } from 'components/views';
import { BusinessAccountType, UserPermission } from 'types/models';

import FeeCommissions from './FeeCommissions';
import Members from './Members';
import View from './View';

const BusinessAccountPage = () => {
  const lang = useLang();

  return (
    <SinglePage.BusinessAccount render={(businessAccount) => (
      <DashboardLayout title={
        businessAccount.type === BusinessAccountType.INDIVIDUAL
          ? lang.get('businessAccount.single.individualTitle', { name: businessAccount.name })
          : lang.get('businessAccount.single.title', { name: businessAccount.name })
      }>
        <Grid.Row gutter={[8, 8]}>
          <Grid.Col span={24}>
            <View businessAccount={businessAccount} />
          </Grid.Col>
          <Grid.Col span={24} md={12}>
            <FeeCommissions businessAccount={businessAccount} />
          </Grid.Col>
          {businessAccount.type !== BusinessAccountType.INDIVIDUAL && (
            <Grid.Col span={24} md={12}>
              <Members businessAccount={businessAccount} />
            </Grid.Col>
          )}
          <Can permissions={
            businessAccount.type === BusinessAccountType.INDIVIDUAL
              ? [UserPermission.INDIVIDUALS_TRX_VIEW, UserPermission.INDIVIDUALS_TRX_UPDATE]
              : [UserPermission.BA_TRX_VIEW, UserPermission.BA_TRX_UPDATE]
          }>
            <Grid.Col span={24}>
              <TransactionsView businessAccount={businessAccount} />
            </Grid.Col>
          </Can>
        </Grid.Row>
      </DashboardLayout>
    )} />
  );
};

export default BusinessAccountPage;
