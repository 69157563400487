import React, { FC, useEffect } from 'react';

import { config } from 'data';
import { formatter, url } from 'helpers';
import { useCountryFormatter, useLang, useModal } from 'hooks';
import { BusinessAccountBalance, TransactionStatus } from 'components/layout';
import { Alert, Button, Descriptions, Form, Link, Modal } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { BusinessAccount, Transaction } from 'types/models';

import DetailsModal from './DetailsModal';
import Title from './Title';

type ViewTransactionModalProps = ModalBaseProps & {
  businessAccount: BusinessAccount;
  transaction: Transaction;
};

const ViewTransactionModal: FC<ViewTransactionModalProps> = ({
  businessAccount,
  transaction,
  open,
  onClose,
}) => {
  const countryFormatter = useCountryFormatter();
  const lang = useLang();
  const detailsModal = useModal();

  useEffect(() => {
    if (!open) {
      detailsModal.hide();
    }
  }, [open, detailsModal]);

  return (
    <Modal
      title={<Title businessAccount={businessAccount} transaction={transaction} />}
      caption={<BusinessAccountBalance businessAccount={businessAccount} />}
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      open={open}
      onCancel={onClose}
    >

      <Form.ActionsItem hidden={!transaction.transferDetail}>
        <Button type="default" onClick={detailsModal.show}>
          {lang.get('transaction.modal.actions.viewDetails')}
        </Button>
      </Form.ActionsItem>

      <Form.Divider hidden={!transaction.transferDetail} />

      <Descriptions
        items={[{
          children: <Alert type="error" message={transaction.errorMsg} />,
          span: 2,
          hidden: !transaction.errorMsg,
        }, {
          label: lang.get('transaction.modal.beneficiary.label'),
          children: transaction.beneficiary ?? '-',
        }, {
          label: lang.get('common.form.country.label'),
          children: countryFormatter.format(transaction.country, '-'),
        }, {
          label: lang.get('transaction.modal.accountId.label'),
          children: transaction.virtualAccount?.id ?? '-',
        }, {
          label: lang.get('transaction.modal.accountNumber.label'),
          children: transaction.virtualAccount?.externalId ?? '-',
        }]}
      />

      <Form.Divider />

      <Descriptions
        items={[{
          label: lang.get('transaction.modal.provider.label'),
          children: transaction.provider?.name ?? '-',
          span: 2,
        }, {
          label: lang.get('transaction.modal.type.label'),
          children: lang.get(`transaction.types.${transaction.transactionType.toLowerCase()}`),
        }, {
          label: lang.get('transaction.modal.payoutMethod.label'),
          children: transaction.payoutMethod
            ? lang.get(`transaction.payoutMethods.${transaction.payoutMethod.toLowerCase()}`)
            : '-',
        }, {
          label: lang.get('common.form.creationDate.label'),
          children: formatter.formatDateTime(transaction.createdAt),
        }, {
          label: lang.get('common.form.status.label'),
          children: <TransactionStatus status={transaction.status} />,
        }]}
      />

      <Form.Divider />

      <Descriptions
        items={[{
          label: lang.get('transaction.modal.originAmount.label'),
          children: formatter.formatCurrency(transaction.originAmount, transaction.originCurrency),
        }, {
          label: lang.get('transaction.modal.destinationAmount.label'),
          children: formatter.formatCurrency(transaction.destinationAmount, transaction.destinationCurrency),
        }, {
          label: lang.get('transaction.modal.currencyRate.label'),
          children: formatter.formatNumber(transaction.currencyRate, config.CURRENCY_RATE_PRECISION),
        }, {
          label: lang.get('transaction.modal.effectiveRate.label'),
          children: formatter.formatNumber(transaction.effectiveRate || transaction.currencyRate, config.CURRENCY_RATE_PRECISION),
        }]}
      />

      <Form.Divider />

      <Descriptions
        items={[{
          label: lang.get('transaction.modal.transactionFee.label'),
          children: formatter.formatCurrency(transaction.transactionFee, transaction.originCurrency),
        }, {
          label: lang.get('transaction.modal.fxMarkup.label'),
          children: formatter.formatCurrency(transaction.fxMarkupFee, transaction.originCurrency),
        }, {
          label: lang.get('transaction.modal.providerFee.label'),
          children: formatter.formatCurrency(transaction.providerFee, transaction.originCurrency),
        }, {
          label: lang.get('transaction.modal.feeDetails.label'),
          children: transaction.feeCommission
            ? <Link href={url.toFeeCommission(transaction.feeCommission.id)}>{lang.get('common.actions.view')}</Link>
            : '-',
        }]}
      />

      <Form.Divider />

      <Descriptions
        items={[{
          label: lang.get('transaction.modal.id.label'),
          children: transaction.id,
        }, {
          label: lang.get('transaction.modal.flowId.label'),
          children: transaction.flowId ?? '-',
        }, {
          label: lang.get('transaction.modal.externalId.label'),
          children: transaction.externalId ?? '-',
        }, {
          label: lang.get('transaction.modal.providerReferenceId.label'),
          children: transaction.providerReferenceId ?? '-',
        }, {
          label: lang.get('transaction.modal.clientReferenceId.label'),
          children: transaction.clientReferenceId ?? '-',
          span: 2,
        }, {
          label: lang.get('common.form.notes.label'),
          children: transaction.notes ?? '-',
        }]}
      />

      <DetailsModal
        transaction={transaction}
        open={detailsModal.open}
        onClose={detailsModal.hide}
      />

    </Modal>
  );
};

export default ViewTransactionModal;
