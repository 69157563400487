import { axios } from 'data';
import { Uuid } from 'types/common';
import { CorridorType } from 'types/models';
import { CorridorTypesParams, CorridorTypesResponse } from 'types/services';

import { preparePaginatableParams, preparePaginatedData } from './helpers';

const getTypes = async (params: CorridorTypesParams = {}) => {
  const response = await axios.get<CorridorTypesResponse>('/payments/admin/currency-corridor/type', {
    params: preparePaginatableParams(params),
  });

  return preparePaginatedData<CorridorType>(response.data);
};

const getType = async (id: Uuid) => {
  const response = await axios.get<CorridorType>(`/payments/admin/currency-corridor/type/${id}`);

  return response.data;
};

const activateType = async (id: Uuid) => {
  await axios.put<never>(`/payments/admin/currency-corridor/type/${id}/set-active/true`);
};

const deactivateType = async (id: Uuid) => {
  await axios.put<never>(`/payments/admin/currency-corridor/type/${id}/set-active/false`);
};

const deleteType = async (id: Uuid) => {
  await axios.delete<never>(`/payments/admin/currency-corridor/type/${id}`);
};

const corridorTypeService = {
  getTypes,
  getType,
  activateType,
  deactivateType,
  deleteType,
};

export default corridorTypeService;
